import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";

import phonesmallicon from "../../../assets/images/successfulcards/phone-small-icon.png";

import mapsmallicon from "../../../assets/images/successfulcards/map-small-icon.png";

import envelope from "../../../assets/images/successfulcards/envelope-fill.svg";

import clockicon from "../../../assets/images/myregisteration/clock-icon.png";

import Breadcrumbsbanner from "../breadcumbbanner/breadcumbsbanner";
import moment from "moment";
import { paymentStatus } from "../../../assets/data/commonData";
import { Button, Modal } from "react-bootstrap";
import KanhaRunService from "../../services/kanha.service";
import backn from "../../../assets/images/backIconSummary.svg";
import { navigate } from "gatsby";
// import logOutimg from "../../../assets/images/logOut.png";
import { CreateImageFromInitials, GetRandomColor } from "../../utils/common";
import { lStorage } from "../../utils/storage";
import Loading from "../loading.jsx";

export default function ParticipantDetails(props) {
  const propsDetails = lStorage.get("detailsList");
  const [loading, setLoading] = useState(false);
  const [pdfData, setPdfData] = useState(null);

  const [name, setName] = useState(null);

  const handleDownload = async (participant_id, first_name, last_name) => {
    setLoading(true);
    kanhaApi
      .certificateDownload(participant_id)
      .then((response) => {
        setLoading(false);
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            first_name = first_name + " " + last_name;
            first_name = first_name.replace(/\s+/g, "");
            setName(first_name);
            setPdfData(response.data.base64Pdf);
          } else {
            // console.log("error");
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  useEffect(() => {
    if (pdfData) {
      const binaryData = atob(pdfData);
      const uint8Array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download =
        "Granules_GKR_Participant_Certificate_" + name + "_19_Nov_2023.pdf";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfData]);

  const kanhaApi = new KanhaRunService();

  const backToParticipant = () => {
    lStorage.remove("detailsList");
    navigate("/myregisterationlist");
  };
  const proceedToPay = () => {
    setLoading(true);
    const payLoad = {
      pnr_no: propsDetails.pnr_no,
    };
    kanhaApi
      .retrypayment(payLoad)
      .then((response) => {
        setLoading(false);
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            window.open(response.data.message.paymentUrl, "_parent");
          } else {
            // console.log("error");
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const backToList = () => {
    navigate("/myregisterationlist");
  };
  // const logOut = () => {
  //   lStorage.remove("userInfo");
  //   lStorage.remove("verification_id");
  //   lStorage.remove("list");
  //   navigate("/");
  // };

  return (
    <>
      <Breadcrumbsbanner />
      <div className="succesfulcards-wrapper inner-pages p-4">
        <div className="container-fluid" style={{ marginBottom: "120px" }}>
          <div className="col-md-12">
            <div className="row">
              <div style={{ padding: "0.5rem 0.5rem 0.5rem 0rem !important" }}>
                <Button
                  className="btn btn-primary"
                  // style={{ margin: "0 12px" }}
                  onClick={backToList}
                >
                  {/* <span onClick={backToList} className="hand"> */}
                  <img className="img-fluid" src={backn} alt="back" />
                  {/* </span>{" "} */}
                  Back to My Registration
                </Button>
                {/* <Button
                  className=" btn btn-primary btn-block"
                  style={{ float: "right" }}
                  onClick={logOut}
                >
                  Logout{" "}
                  <img
                    className="img-fluid"
                    src={logOutimg}
                    width="15px"
                    alt="Clock logo"
                  />
                </Button> */}
              </div>
              <div style={{ paddingTop: "20px" }}>
                <b>{propsDetails && propsDetails.pnr_no} </b> &nbsp;&nbsp;
                <span className="partici-mob">
                  ( Participants Count :
                  {propsDetails &&
                    propsDetails.participant &&
                    propsDetails &&
                    propsDetails.participant.length}
                  )
                </span>
                <>
                  <br />
                  {/* <span> Total Amount : </span>
                  <span>
                    <b> {propsDetails && propsDetails.amount} ₹ </b>
                  </span> */}
                  <br />
                  <span>
                    <img
                      className="img-fluid"
                      src={clockicon}
                      alt="Clock logo"
                    />
                    &nbsp;{" "}
                    {moment(propsDetails && propsDetails.created_at).format(
                      "DD MMM h:mm a"
                    )}
                  </span>
                  <br />
                  <b>Payment Status :</b>{" "}
                  <span
                    style={{
                      color:
                        propsDetails?.payment_status === "PAYMENT_PENDING" ||
                        propsDetails?.payment_status === "ORDER_FAILED"
                          ? "#DB463A"
                          : "#0CAE10",
                    }}
                  >
                    <b>
                      {paymentStatus(
                        propsDetails && propsDetails.payment_status
                      )}{" "}
                      &nbsp;&nbsp;
                    </b>
                  </span>
                </>
              </div>
              <div>
                {propsDetails &&
                  propsDetails?.payment_failed_reason &&
                  propsDetails?.payment_status !== "ORDER_CONFIRMED" && (
                    <p>
                      <b>Reason : </b>{" "}
                      {propsDetails?.payment_failed_reason ===
                      "PG_LATE_AUTH_TIMEOUT"
                        ? "Your payment processing time limit exceeded."
                        : propsDetails?.payment_failed_reason}
                    </p>
                  )}
              </div>
            </div>
          </div>

          <div className="row">
            {propsDetails &&
              propsDetails.participant &&
              propsDetails &&
              propsDetails.participant
              .sort((a, b) => a.race_category_id - b.race_category_id)
              .map((data, index) => {
                return (
                  <div className="col-md-3 col-sm-6" key={index + 1}>
                    <div className="card succesful-cards-wrapper first-upper">
                      <Card>
                        <Card.Body>
                          <div className="name-wrapper-item">
                            <span>
                              <img
                                id="preview"
                                className="image-fluid"
                                width="35px"
                                src={CreateImageFromInitials(
                                  500,
                                  data.first_name,
                                  GetRandomColor()
                                )}
                                alt=""
                              />
                            </span>
                          </div>

                          <ul style={{ paddingTop: "10px" }}>
                            <li>
                              <b>Unique Id</b>
                            </li>
                            <li>{data.unique_id}</li>

                            <li>
                              <b>Name</b>
                            </li>
                            <li>
                              {data.first_name} {data.last_name}
                            </li>
                            <li>
                              <b>Gender</b>
                            </li>
                            <li>{data.gender}</li>
                            {data?.blood_group != "" && (
                              <>
                                {data?.race_km?.includes("virtual-run") ===
                                  false && (
                                  <>
                                    <li>
                                      <b>Blood group </b>
                                    </li>
                                    <li>{data.blood_group}</li>
                                  </>
                                )}
                              </>
                            )}
                            {data?.dob != "" && data?.dob != "0000-00-00" && (
                              <li>
                                <b>DOB</b>
                              </li>
                            )}
                            {data?.dob != "" && data?.dob != "0000-00-00" && (
                              <li>
                                {moment(data && data.dob).format("DD-MM-YYYY")}
                              </li>
                            )}

                            {(data?.dob == "" || data?.dob == "0000-00-00") && data?.age != "" &&  (
                              <li>
                                <b>Age</b>
                              </li>
                            )}
                            {(data?.dob == "" || data?.dob == "0000-00-00") && data?.age != "" && <li>{data.age}</li>}

                            {/* <li><b>BIB Name</b></li>
                            <li>
                               {data.name_on_bib} 
                            </li> */}
                            {data?.t_shirt_size != "" && (
                              <li>
                                <b>T-shirt Size</b>
                              </li>
                            )}
                            {data?.t_shirt_size != "" && (
                              <li>{data.t_shirt_size}</li>
                            )}
                          </ul>

                          <div>
                            <ul className="contact-wrapper-box">
                              <li>
                                <span>
                                  <img
                                    className="img-fluid"
                                    src={phonesmallicon}
                                    alt="Clock logo"
                                  />
                                </span>
                                {data.contact_number}
                              </li>
                              <li style={{ textTransform: "none" }}>
                                <span>
                                  <img
                                    className="img-fluid"
                                    src={envelope}
                                    alt="Clock logo"
                                  />
                                </span>
                                {data.email_address}
                              </li>
                              <li>
                                <span>
                                  <img
                                    className="img-fluid"
                                    src={mapsmallicon}
                                    alt="Clock logo"
                                  />
                                </span>
                                {data.address}
                              </li>
                              {data?.overnight_accommodation != "" && (
                                <li>
                                  <b>Accommodation</b> :{" "}
                                  {data.overnight_accommodation === "Y"
                                    ? "Yes"
                                    : "No"}
                                </li>
                              )}
                              {data?.bib_collection_location != "" && (
                                <li>
                                  <b>BIB collection location</b> :{" "}
                                  {data.bib_collection_location}
                                </li>
                              )}
                              <li>
                                <b>Race Category</b> : {data.race_category_name}
                              </li>
                              {Number(data.gift_a_tree_count) > 0 && (
                                <li>
                                  <b>Gift A Tree Count</b> :{" "}
                                  {data.gift_a_tree_count}
                                </li>
                              )}
                               {Number(data.gift_a_run) > 0 && (
                                <li>
                                  <b>Gift A Run Count</b> :{" "}
                                  {data.gift_a_run}
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className="amount-item-wrapper">
                            {data?.discount_price > 0 ? (
                              <>
                                <h4>
                                  {" "}
                                  <span className="strike">
                                    ₹ {data.race_price}
                                  </span>{" "}
                                  <span>₹ {data.final_amount}</span>{" "}
                                </h4>
                              </>
                            ) : (
                              <>
                                <h4> ₹ {data?.final_amount}</h4>
                              </>
                            )}
                            {/* {propsDetails?.payment_status ===
                              "ORDER_CONFIRMED" &&
                              (!(
                                data?.race_category_id == 2 ||
                                data?.race_category_id == 3 ||
                                data?.race_category_id == 4
                              ) ? (
                                <button
                                  onClick={() =>
                                    handleDownload(
                                      data.participant_id,
                                      data.first_name,
                                      data.last_name
                                    )
                                  }
                                  className="btn"
                                  style={{
                                    backgroundColor: "#0CAE10",
                                    color: "#fff",
                                  }}
                                >
                                  Download Certificate
                                </button>
                              ) : (
                                <div>
                                  <button
                                    onClick={() =>
                                      window.open(
                                        "https://www.timingmiles.com/result-page2.php?eid=66&cid=162",
                                        "_blank"
                                      )
                                    }
                                    className="btn"
                                    style={{
                                      backgroundColor: "#0CAE10",
                                      color: "#fff",
                                    }}
                                  >
                                    Download Certificate
                                  </button>
                                  <h4 style={{ color: "#fb8c05" }}>
                                    Coming Soon
                                  </h4> 
                                </div>
                              ))} */}
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="col-md-3 col-sm-6">
            {/* <Button className="btn btn-primary" onClick={backToParticipant}>
            <img className="img-fluid" src={backn} alt="back"/>
              Back to Summary
            </Button> */}
            &nbsp;&nbsp;
              {propsDetails && propsDetails.retrypayment === 1 && (
              <Button className="btn btn-warning" onClick={proceedToPay}>
                Retry Payment
            </Button> 
            )}
          </div>
        </div>
        {loading === true && (
          <Modal id="loading" show={loading} style={{ marginTop: "50vh" }}>
            <Loading />
          </Modal>
        )}
      </div>
    </>
  );
}
