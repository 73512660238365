import React from "react";
import Seo from "../components/seo/index";
import Layout from "../layout/index";
import ParticipantDetails from "../components/sharedComponents/participant-details/participantDetails";

export default function ParticipantsD({ location }) {
  const dataValue =
    location.state && location.state.editData !== undefined
      ? location.state.editData
      : null;
  return (
    <>
      <div>
        <Seo title="Green Heartfulness Run 2024 - Order Details" />
        <Layout isSticky>
          <section>
            <ParticipantDetails data={dataValue} />
          </section>
        </Layout>
      </div>
    </>
  );
}
